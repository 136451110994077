import React, { Component } from 'react';
import { baseUrl } from '../../Helpers/route-helpers';

class Confirmation extends Component {
    componentDidMount() {
        fetch(`${baseUrl}/confirmation`, {
                method: 'post',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({
                    data : this.props.data
                })
            })
            .then(res => res.json())
            .then(response => {
                if (response) {
                    this.props.handleConfirm();
                }
            });
    }

    render() {
        return (
        <div>
            Sending out anonymous emails to your participants now!
        </div>
        )
    }
};

export default Confirmation;