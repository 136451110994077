export const convertDateToString = (date) => {
  let dateArray = date.split("-");
  let year = dateArray[0];
  let month = dateArray[1];
  let day = dateArray[2];

  switch (parseInt(month)) {
    case 1:
      month = "January";
      break;
    case 2:
      month = "February";
      break;
    case 3:
      month = "March";
      break;
    case 4:
      month = "April";
      break;
    case 5:
      month = "May";
      break;
    case 6:
      month = "June";
      break;
    case 7:
      month = "July";
      break;
    case 8:
      month = "August";
      break;
    case 9:
      month = "September";
      break;
    case 10:
      month = "October";
      break;
    case 11:
      month = "November";
      break;
    case 12:
      month = "December";
      break;
    default:
      throw Error("Error processing date");
  }

  return `${month} ${day}, ${year}`;
}

export const toggleParticles = () => {
  const particles = document.body.querySelector('.foreground');

  if (particles.style.visibility === "hidden") {
    particles.style.visibility = "visible";
  } else {
    particles.style.visibility = "hidden";
  }
}