import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import FormPage from './Pages/Form';
import KeyPage from './Pages/Key';
import * as serviceWorker from './serviceWorker';

const router = createBrowserRouter([
  {
    path: '/',
    element: <FormPage />
  },
  {
    path: '/key',
    element: <KeyPage />
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
    ,document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();