import React, { Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Inputs from './form-inputs';
import Confirmation from './form-confirmation';
import { baseUrl } from '../../Helpers/route-helpers';
import { convertDateToString } from '../../Helpers/form-helpers';
import './form.css';

const RECAPTCHA_KEY = "6LeexKkUAAAAADsRyKjmp3hbkfQCykn4_862c1dn";
const DEFAULT_STATE = {
    participants: 0,
    partArr: [],
    emails: {},
    names: {},
    details: {},
    final: {},
    captcha: "",
    flip: false,
    sent: false
};

class Form extends Component {
    constructor() {
        super();
        this.state = {
            participants: 0,
            partArr: [],
            emails: {},
            names: {},
            details: {},
            final: {},
            captcha: "",
            flip: false,
            sent: false
        }

        this.onChangeParts = this.onChangeParts.bind(this);
        this.onChangeDetails = this.onChangeDetails.bind(this);
        this.handleSubmitParts = this.handleSubmitParts.bind(this);
        this.populateParts = this.populateParts.bind(this);
        this.changePartEmails = this.changePartEmails.bind(this);
        this.changePartNames = this.changePartNames.bind(this);
        this.handleRecaptcha = this.handleRecaptcha.bind(this);
        this.submitPartEmails = this.submitPartEmails.bind(this);
        this.resetState = this.resetState.bind(this);
    }

    onChangeParts = event => {
        this.setState({ participants: event.target.value })
    }

    onChangeDetails = event => {
        const { name, value } = event.target;
        this.setState({
            details: {
                ...this.state.details,
                [name]: name === "date" ? convertDateToString(value) : value,
            }
        });
    }

    handleSubmitParts = event => {
        if (this.state.participants > 1 && this.state.participants < 100) {
            this.populateParts();
            this.setState({ flip: true });
        } else if (this.state.participants > 99) {
            alert('This game currently only supports a maximum of 99 players. Larger games coming soon!')
        } else {
            alert('This game can only be played with more than one player!')
        }
        console.log(this.state.details);
        event.preventDefault();
    }

    populateParts = () => {
        for (let i = 0; i < this.state.participants; i++) {
            this.state.partArr.push(i);
        }
    }

    changePartEmails = (event) => {
        this.setState({
            emails: { ...this.state.emails, [event.target.name]: event.target.value }
        });
    }

    changePartNames = (event) => {
        this.setState({
            names: { ...this.state.names, [event.target.name]: event.target.value }
        });
    }

    handleRecaptcha = value => {
        this.setState({ captcha: value });
    }

    submitPartEmails = () => {
        // Early check : All emails have been filled out. 
        // TO-DO: Cover this check with validation.
        let invalidEmails = "";
        for (let item of this.state.partArr) {
            if (!this.state.emails[item]) {
                invalidEmails += (item + 1) + ", ";
            }
        }

        if (invalidEmails.length < 1) {
            fetch(`${baseUrl}/submitform`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json, text/plan, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    participants: this.state.participants,
                    details: this.state.details,
                    names: this.state.names,
                    emails: this.state.emails,
                    captcha: this.state.captcha
                })
            })
                .then(res => res.json())
                .then(response => {
                    this.setState({
                        final: response,
                    });
                    console.log(this.state.final);
                });
        }
        else alert("These emails must be filled: " + invalidEmails);
    }

    handleConfirm = () => {
        this.setState({ sent: true });
    }

    resetState = () => {
        if (window.confirm("Are you sure you wish to reset this form?")) this.setState(DEFAULT_STATE);
    }

    render() {
        return (
            this.state.flip === false ?
                <form className="intro" onSubmit={this.handleSubmitParts}>
                    <label htmlFor="host">Who is hosting this event?</label><br />
                    <input type="text" name="host" id="parts" value={this.state.value} minLength="1" maxLength="40" width="10" onChange={this.onChangeDetails}></input><br />
                    <label htmlFor="date">When is the event?</label><br />
                    <input type="date" name="date" id="parts" value={this.state.value} minLength="1" maxLength="40" width="10" onChange={this.onChangeDetails}></input><br />
                    <label htmlFor="loc">Where will the exchange be held?</label><br />
                    <input type="text" name="loc" id="parts" value={this.state.value} minLength="1" maxLength="40" width="10" onChange={this.onChangeDetails}></input><br />
                    <label htmlFor="time">What time is the event?</label><br />
                    <input type="text" name="time" id="parts" value={this.state.value} minLength="1" maxLength="10" width="10" onChange={this.onChangeDetails}></input><br />
                    <label htmlFor="parts">How many people are participating?</label><br />
                    <input type="text" name="parts" id="parts" onChange={this.onChangeParts} value={this.state.value} minLength="1" maxLength="4" width="10"></input><br />
                    <button type="submit" value="Submit">Submit</button>
                    <div class="form-bottom"></div>
                </form> :
                (!this.state.final.token) ?
                    <div className="parts">
                        <div className="form-text">Please enter participants here:</div>
                        <ul>
                            {this.state.partArr.map(item => (
                                <li key={item}>{
                                    <Inputs item={item} changePartEmails={this.changePartEmails} changePartNames={this.changePartNames} />
                                }</li>
                            ))}
                        </ul>
                        <div>
                            <ReCAPTCHA
                                sitekey={RECAPTCHA_KEY}
                                onChange={this.handleRecaptcha}
                            />
                        </div>
                        <button type="submit" value="Submit" onClick={this.submitPartEmails}>Submit</button><br />
                        <button type="reset" value="Reset" onClick={this.resetState}>Reset</button>
                        <div class="form-bottom"></div>
                    </div>
                    :
                    (!this.state.sent) ?
                        <div>
                            <Confirmation data={this.state.final} sent={this.state.sent} handleConfirm={this.handleConfirm} />
                        </div>
                        :
                        <div className="post-confirm">
                            Emails have been sent to your recipients, please use key {this.state.final.token} in the future if you wish access your key!
                        </div>
        )
    };
}

export default Form;

