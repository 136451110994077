import React, { Component } from 'react';
import './key.css';
import { baseUrl } from '../../Helpers/route-helpers';

class Key extends Component {
    constructor() {
        super();

        this.state = {
            key : '',
            approved : false,
            matches : []
        };
    }

    handleKeyChange = event => {
        this.setState({key: event.target.value });
    }

    submitKey = event => {
        event.preventDefault();
        const key = this.state.key;
        let matches = [];

        fetch(`${baseUrl}/submitkey?key=${key}`)
        .then(res => res.json())
        .then(response => {
            if (response) {
                this.setState({approved: true})
                for (let num in response) {
                    let tmp = [];
                    tmp.push(response[num]["giver"][0]);
                    tmp.push(response[num]["receiver"][0]);
                    matches.push(tmp);
                }

                this.setState({matches : matches});
            }
        })
        .catch(e => console.log("Error retrieving key")); 
    }

    render() {
        return(
            this.state.approved === false ?
            <div className="content">
                <form className="key-entry" onSubmit={this.submitKey}>
                    <label htmlFor="gamekey">Please enter your previously assigned game key:</label><br />
                    <input id='key-input' type="text" name="gamekey" onChange={this.handleKeyChange} width="10" placeholder="Key goes here."></input> <br />
                    <button type="submit" value="Submit">Submit</button>
                </form>
            </div>
            :
            <div className="content participants">
                {this.state.matches.length !== 0 ? 
                <ul>
                    {this.state.matches.map((match, i) => {
                        return <li key={i}><span>{match[0]}</span> &#8594; <span>{match[1]}</span></li>
                    })}
                </ul>
                :
                <div>Error retrieving key!</div>}
            </div>
        )
    }
}

export default Key;