import React from 'react';
import './form-inputs.css';

const Inputs = ({ item, changePartEmails, changePartNames }) => {
    return (
        <div className="form-container">
            <label htmlFor="name">Name</label>
            <p><input type="text" htmlFor="name" name={item} onChange={changePartNames}></input></p>
            
            <div className="email">
                <label htmlFor="email">Email</label>
                <p><input type="email" htmlFor="email" name={item} onChange={changePartEmails}></input></p>
            </div>
        </div>
    )
}

export default Inputs;