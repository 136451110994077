import React from 'react';
import { Link } from 'react-router-dom';
import './header.css';

const Header = ({ toggleParticles }) => {
    const handleClosePopup = () => {
        const graphic = document.getElementsByClassName("graphic")[0];
        graphic.style.visibility = "hidden";
    }

    return (
        <div className="head-container">
            <div className="title"><h1><Link to='/'>Secret Santa</Link></h1></div>
            <div className="popup-wrapper">
                <div className="popup-container">
                    <div className="graphic">
                        <div className="box">
                            <div className="exit" onClick={handleClosePopup}>X</div>
                            <p>Tired of being the one always putting in all the work pulling names out of the hat? 
                            Let us do the work this go around by randomizing your list for you.</p>
                            <p>Here we try to create a bit more anonymity by randomizing the list for you and locking away the key!  
                            <strong> Get started by entering in the amount of people that will be participating in this year's event.</strong></p>
                        </div>
                        <div className="arrow-down"></div>
                    </div>
                </div>
            </div>
            <div className="toggle"> <span onClick={toggleParticles}>Toggle Snow</span> <Link to="/key"><span>Enter Key</span></Link></div>
            <div className="fill"></div>
        </div>
    );
}

export default Header;

