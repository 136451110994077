import React from "react"
import Header from "../Components/Header/header"
import Particles from "react-particles-js"

import Form from "../Components/Form/form"

import { params } from "../Configs/particle"
import { toggleParticles } from "../Helpers/form-helpers"

export default function FormPage() {
  return (
    <>
      <div className="container">
        <Particles className="foreground" params={params} />
        <div className="leftblock"></div>
        <div className="center">
          <Header toggleParticles={toggleParticles} />
          <div id="content">
            <Form />
          </div>
        </div>
        <div className="rightblock"></div>
      </div>
    </>
  )
}

